//imports
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

//styling
import './NavBar.css';

//components 
import Icon from '../Icon';
import Hamburger from "../Hamburger";

export default function NavBar() {
    
    return (
        <nav>
            <ul className="left-nav-bar">
                <NavLink className="nav-link" to="/" >Home</NavLink>
                <NavLink className="nav-link" to="/about" >About</NavLink>
                <NavLink className="nav-link" to="/portfolio" >Portfolio</NavLink>
                <NavLink className="nav-link" to="/contact" >Contact</NavLink>
                <Hamburger 
                />
            </ul>
            <ul className="right-nav-bar">
                <Icon src={require("../../images/gitHubIcon.png")} href="https://github.com/SheynaLew"/>
                <Icon src={require("../../images/linkInIcon.png")} href="https://www.linkedin.com/in/jenny-lewis-gandy-01795895/" />
            </ul>
        </nav>
    )
};