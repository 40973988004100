//imports
import React from "react";

//styling
import './Button.css';

export default function Button({onClick, text, id}) {
    return (
        <button
            onClick={onClick}
            className='btn' 
            data-testId='btn'
            id={id}
        >
        {text}
        </button>
    )
};
