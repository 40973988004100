import { useState, useEffect } from "react";
import { Link, useLocation  } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import "./Hamburger.css";

function Hamburger() {

  //variables
  const location = useLocation();
  // console.log(location);

  //useStates
  const [hamburgerHidden, setHamburgerHidden] = useState(true);
  const [hamburgerP, sethamburgerP] = useState("");
  const [hamburgerDiv, sethamburgerDiv] = useState("")

    //functions
  function togglehamburgerHidden() {
    setHamburgerHidden(!hamburgerHidden);
  };

   //ifStatement within a useEffect hook
  useEffect(() => {
    if (location.pathname === "/") {
      sethamburgerP("home-hamburger");
      sethamburgerDiv("home-hamburger");
    }  
    if (location.pathname === "/about") {
      sethamburgerP("about-hamburger");
      sethamburgerDiv("about-hamburger");
    }  
    if (location.pathname === "/portfolio") {
      sethamburgerP("portfolio-hamburger");
      sethamburgerDiv("portfolio-hamburger");
    } 
    if (location.pathname === "/contact") {
      sethamburgerP("contact-hamburger");
      sethamburgerDiv("contact-hamburger");
    }
  })  
  

  return (
    <div className="hamburger">
      <button onClick={togglehamburgerHidden} className="hamburger-dropbtn">
        <GiHamburgerMenu size={28} />{" "}
      </button>
      <div id={hamburgerDiv}
        className={
          hamburgerHidden ? "hidden-hamburger-content" : "hamburger-content"
        }
      >
        <Link style={{ textDecoration: "none" }} to="/">
          <p className={hamburgerP}>Home</p>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/about">
          <p className={hamburgerP}>About</p>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/portfolio">
          <p className={hamburgerP}>Portfolio</p>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/contact">
          <p className={hamburgerP}>Contact</p>
        </Link>
      </div>
    </div>
  );
}

export default Hamburger;
