import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
// import NavBar from '../src/components/NavBar';
import {BrowserRouter as Router, Route, Routes } from "react-router-dom";


//css
import './index.css';

//pages 
import Home from '../src/Pages/Home';
import About from '../src/Pages/About';
import Portfolio from '../src/Pages/Portfolio';
import Contact from '../src/Pages/Contact';


ReactDOM.render(

    <Router>
      {/* <NavBar className={}/> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
