import React, {useState} from 'react';


// css
import './About.css';


//components
import AboutCard from '../../components/AboutCard';
import CircleImg from '../../components/CircleImg'
import NavBar from '../../components/NavBar';
import NavigationLink from '../../components/NavigationLink';

// Array of AboutCard text
let AboutCardTextArray = [
    "My background prior to studying and coding with the School of Code is in Project Coordination. Prior to this I've worked as a chef in a variety of restaurants and hotels. But with the world fast moving towards heavy reliance on technology I was interested in peeking behind the curtain of the tech we use on a daily basis. What I found wasn't a world of maths and confusion as I'd always believed software engineering to be. Instead I found a world of creativity, and I was drawn to learn more. I spent 16 intense weeks at the School of Code in their 8th Bootcamp, and now I'm confident building full stack apps using Javascript, Node JS, and React JS, and eager to learn as many more new languages as I can!",
    "I started my coding journey with the School of Code in November 2021. Prior to this I hadn't written a line of code. But in just 16 weeks I was developing my own apps, practicing Agile methodologies, and was comfortable working in pairs or teams to write my code. If that's what I can learn in 16 weeks, imagine what I can do with more time!",
    "I've loved cooking since I was about 9, probably younger. I love the creativity you can have in the kitchen, the experimentation with flavours, the various ways you can make just one dish. I suppose this is why I was so drawn to software engineering; that same creative and space to experiment exists in the tech world as it does in a kitchen.",
    "When I was 5 my Nana taught me to knit. Since then a mild obsession with all things crafty and yarn-related has gripped me. In 2020 I started learning macrame and making rainbow wall hangings. I set up an Etsy shop selling them (JennyWrensNest) and would love to do craft fairs in the future. One project I hope to soon show you in my portfolio is my own e-commerce site to sell my rainbows!"
]


function About() {

//functions and states
    //AboutCard states
        //General
    const [aboutImgGeneralClicked, setAboutImgGeneralClicked] = useState(true);
    const [aboutCardId, setAboutCardId] = useState("about-hidden");
    const [aboutCardText, setAboutCardText] = useState();
        //Coding
    const [aboutImgCodingClicked, setAboutImgCodingClicked] = useState(true);
        //Cooking
    const [aboutImgCookingClicked, setAboutImgCookingClicked] = useState(true); 
         //Crafting
    const [aboutImgCraftingClicked, setAboutImgCraftingClicked] = useState(true);
   

    //CircleImg states
        //General
    const [aboutImgGeneralId, setAboutImgGeneralId] = useState("about-general-img");
        //Coding
    const [aboutImgCodingId, setAboutImgCodingId] = useState("about-coding-img");
        //Cooking
    const [aboutImgCookingId, setAboutImgCookingId] = useState("about-cooking-img");
        //Crafting
    const [aboutImgCraftingId, setAboutImgCraftingId] = useState("about-crafting-img");


    //NavigationLink states
        //all
    const [aboutNavText, setAboutNavText] = useState();
    const [aboutNavLink, setAboutNavLink] = useState();

    //container div states
    const [aboutContainerId, setAboutContainerId] = useState();


    //AboutCardGeneral function
    function showAboutCardGeneral() {
        //the visibility of the AboutCard will be dependent on it's corresponding CircleImg being clicked, as will the size and position of the CircleImg
        if (aboutImgGeneralClicked === true) {
            setAboutContainerId("divFlipped");
            setAboutCardText(AboutCardTextArray[0]);
            setAboutCardId("about-visible");
            setAboutNavLink("https://schoolofcode.co.uk/");
            setAboutNavText("School of Code");
            setAboutImgGeneralId("about-general-img-large");
            setAboutImgCodingId("about-hidden");
            setAboutImgCookingId("about-hidden");
            setAboutImgCraftingId("about-hidden");
            setAboutImgGeneralClicked(false);
        } else {
            setAboutContainerId();
            setAboutCardText();
            setAboutCardId("about-hidden");
            setAboutNavLink();
            setAboutNavText();
            setAboutImgGeneralId("about-general-img");
            setAboutImgCodingId("about-img-visible");
            setAboutImgCookingId("about-img-visible");
            setAboutImgCraftingId("about-img-visible");
            setAboutImgGeneralClicked(true);
        }
    }

    //AboutCardCoding function
    function showAboutCardCoding() {
    if (aboutImgCodingClicked === true) {
        setAboutContainerId("divFlipped");
        setAboutCardText(AboutCardTextArray[1]);
        setAboutCardId("about-visible");
        setAboutNavLink("https://schoolofcode.co.uk/");
        setAboutNavText("School of Code");
        setAboutImgCodingId("about-coding-img-large");
        setAboutImgGeneralId("about-hidden");
        setAboutImgCookingId("about-hidden");
        setAboutImgCraftingId("about-hidden");
        setAboutImgCodingClicked(false);
    } else {
        setAboutContainerId();
        setAboutCardText();
        setAboutCardId("about-hidden");
        setAboutNavLink();
        setAboutNavText();
        setAboutImgCodingId("about-coding-img");
        setAboutImgGeneralId("about-img-visible");
        setAboutImgCookingId("about-img-visible");
        setAboutImgCraftingId("about-img-visible");
        setAboutImgCodingClicked(true);
    }
}

    //AboutCardCooking function
    function showAboutCardCooking() {
    if (aboutImgCookingClicked === true) {
        setAboutContainerId("divFlipped");
        setAboutCardText(AboutCardTextArray[2]);
        setAboutCardId("about-visible");
        setAboutImgCookingId("about-cooking-img-large");
        setAboutImgGeneralId("about-hidden");
        setAboutImgCodingId("about-hidden");
        setAboutImgCraftingId("about-hidden");
        setAboutImgCookingClicked(false);
    } else {
        setAboutContainerId();
        setAboutCardText();
        setAboutCardId("about-hidden");
        setAboutImgCookingId("about-cooking-img");
        setAboutImgGeneralId("about-img-visible");
        setAboutImgCodingId("about-img-visible");
        setAboutImgCraftingId("about-img-visible");
        setAboutImgCookingClicked(true);
    }
}

    //AboutCardCrafting function
    function showAboutCardCrafting() {
    if (aboutImgCraftingClicked === true) {
        setAboutContainerId("divFlipped");
        setAboutCardText(AboutCardTextArray[3]);
        setAboutCardId("about-visible");
        setAboutNavLink("https://www.etsy.com/uk/shop/JennyWrenNest");
        setAboutNavText("Jenny Wrens Nest");
        setAboutImgCraftingId("about-crafting-img-large");
        setAboutImgGeneralId("about-hidden");
        setAboutImgCodingId("about-hidden");
        setAboutImgCookingId("about-hidden");
        setAboutImgCraftingClicked(false);
    } else {
        setAboutContainerId();
        setAboutCardText();
        setAboutCardId("about-hidden");
        setAboutNavLink();
        setAboutNavText();
        setAboutImgCraftingId("about-crafting-img");
        setAboutImgGeneralId("about-img-visible");
        setAboutImgCodingId("about-img-visible");
        setAboutImgCookingId("about-img-visible");
        setAboutImgCraftingClicked(true);
    }
}


    return(
        <div className='about'>
            <NavBar/>
            <div className='about-container' id={aboutContainerId}>
                <CircleImg src={require("../../images/profilePicture.jpg")} id={aboutImgGeneralId} onClick={showAboutCardGeneral}/>
                <CircleImg src={require("../../images/codingImages.jpeg")} id={aboutImgCodingId} onClick={showAboutCardCoding}/>
                <CircleImg src={require("../../images/nathan-dumlao-bRdRUUtbxO0-unsplash.jpg")} id={aboutImgCookingId}onClick={showAboutCardCooking}/>
                <CircleImg src={require("../../images/jennywrensnest.jpeg")} id={aboutImgCraftingId}onClick={showAboutCardCrafting}/>
                <div className="about-text-and-link" id={aboutCardId}>
                    <AboutCard text={aboutCardText}/> 
                    <NavigationLink text={aboutNavText} link={aboutNavLink}/>
                </div>
            </div>
        </div>
    )
}

export default About