//imports
import React from "react";

//styling
import './AboutCard.css';

export default function AboutCard({text, id, h3}) {
    return (
        <div className="about-card" data-testId="aboutCard" id={id}>
            <h3>{h3}</h3>
            <p>{text}</p>
        </div>
    )
};