//imports
import React from "react";

//styling
import './NavigationLink.css';

export default function NavigationLink({text, link}) {
    return (
        <a href={link} target="_blank">
            <p classname="navigation-link">{text}</p>
        </a>
    )
};