//imports
import React from "react";

//styling
import './CircleImg.css';

export default function CircleImg({src, alt, onClick, id}) {

    //button function 
        //onClick should:
            //stop all the other CircleImg components from moving
            //resize the clicked CircleImg component (larger)
            //resize the remaining CircleImg components (smaller)
            //move the clicked CircleImg component (left center)
            //move the remaining Circle Img components (right, column)
            //reveal the hidden AboutCard


    return (
            <img src={src} alt={alt} onClick={onClick} className="circle-img" id={id}/>
    )
};