import React, { useState } from 'react';
import emailjs from 'emailjs-com';

//css
import './ContactForm.css';
import Button from '../Button';

export default function ContactForm() {

    //states
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    //functions
    const submit = () => {
        if (name && email && message) {
            const serviceId = 'service_xf1u81r';
            const templateId = 'template_m9x85dv';
            const userId = 'MpITOOd5Jg2NEhnEi';
            const templateParams = {
                name,
                email,
                message
            };

            emailjs.send(serviceId, templateId, templateParams, userId)
                .then(response => console.log(response))
                .then(error => console.log(error));
    
            setName('');
            setEmail('');
            setMessage('');
            setEmailSent(true);
        } else {
            alert('Please fill in all fields.');
        }
    }

    const isValidEmail = email => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };



    return (
        <div id="contact-form">
            <h4>Contact Me</h4>
            <input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
            <input type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
            <textarea placeholder="Message" value={message} onChange={e => setMessage(e.target.value)}></textarea>
            {/* <button onClick={submit}>Submit</button> */}
            <Button onClick={submit} text="Submit"/>
            {/* <span className={emailSent ? 'visible' : null}>Thank you for your message!</span> */}
        </div>
    );
};

