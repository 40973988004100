import React, {useState} from 'react';
import ReactPlayer from 'react-player';


// css
import './Portfolio.css';


//components
import NavBar from '../../components/NavBar';
import SquareImg from '../../components/SquareImg';
import Button from '../../components/Button';
import ProjectTitle from '../../components/ProjectTitle';
import AboutCard from '../../components/AboutCard';


//array of AboutProjectTitle text
let AboutProjectTitle = [
    "Breastfeeding App",
    "MooBorn - School of Code",
]


//array of ProjectVideo links 
let ProjectVideo = [
    "https://vimeo.com/693259487/0a4faea9e3",
    "https://vimeo.com/694045017/eb8d2ee7e9",
    
]


//arrays of AboutCard text
let AboutCardTextWhy = [
    "In a breastfeeding support group of which I am a member, parents are frequently asking other members to help them work out what Breastfeeding award they have achieved in their feeding journey. It made me realise that there should be a quick and easy way to check. Feeding awards are a good motivator for continuing to feed on the tougher days.",
    "For our final 4-week project with the School of Code we were assigned to teams of 6 to create an app which solves a problem. After much ideation we chose a parenting tracker to allow new parents and other care givers to monitor things like sleep, nappies, and feeds. The aim was to allow multiple care givers to connect to one child's records to allow continuity of care."
]


let AboutCardTextHow = [
    "I built this app using React JS and CSS. It's a simple one page app that allows a user to enter the start and stop dates of their feeding journey and then presents them with their award.",
    "We used tools such as Excalidraw, Figma and Trello to ideate our app and plan our working methodology around Agile. We had stand ups and retros each day and pair programmed, with each pair focusing on a front or back end element."
]


let AboutCardTextNext = [
    "I'd like to add features to this app allowing the user to share their award on social media and download their award. I'd also like to add a support page. I learned a lot with this project, including using external components, and using the timestamp effectively.",
    "Whilst the inital idea is good, I think we over-estimated what could be delivered in 4 weeks and tried to implement too many features. I would like to revisit this idea in the future and perfect one element at a time, adding graphs, ensuring multiple users can connect securely to one child's records, and tidying up the CSS."
]


//array of AboutCard links
let AboutCardLink = [
    "https://gallant-ptolemy-7acf48.netlify.app/",
    "https://mooborn.netlify.app/",
]


function Portfolio() {

//states
    const [frontClass, setFrontClass] = useState("front");
    const [backClass, setBackClass] = useState("hide-back");
    const [projectTitle, setProjectTitle] = useState();
    const [projectVideo, setProjectVideo] = useState();
    const [aboutTextWhy, setAboutTextWhy] = useState();
    const [aboutTextHow, setAboutTextHow] = useState();
    const [aboutTextNext, setAboutTextNext] = useState();
    const [aboutLink, setAboutLink] = useState();

//functions
    //hiding and showing front and back components functions
        //hide front, show back (on image click)
    function hideAndShowFeeding() {
        setFrontClass("hide-front"); 
        setProjectTitle(AboutProjectTitle[0]);
        setProjectVideo(ProjectVideo[0]);
        setAboutTextWhy(AboutCardTextWhy[0]);
        setAboutTextHow(AboutCardTextHow[0]);
        setAboutTextNext(AboutCardTextNext[0]);
        setAboutLink(AboutCardLink[0]);
        setBackClass("back");  
    }

    function hideAndShowMooBorn() {
        setFrontClass("hide-front"); 
        setProjectTitle(AboutProjectTitle[1]);
        setProjectVideo(ProjectVideo[1]);
        setAboutTextWhy(AboutCardTextWhy[1]);
        setAboutTextHow(AboutCardTextHow[1]);
        setAboutTextNext(AboutCardTextNext[1]);
        setAboutLink(AboutCardLink[1]);
        setBackClass("back");  
    }

     //hide back, show back (on button click)
    function returnToPortfolio(){
        setFrontClass("front"); 
        setProjectTitle();
        setProjectVideo();
        setAboutTextWhy();
        setAboutTextHow();
        setAboutTextNext();
        setAboutLink();
        setBackClass("hide-back"); 
    }


return (
    <div className="Portfolio">
        <NavBar id="portfolio-navbar"/>
        <div className={frontClass}>
            <div className='front-topRow'>
                <div className="sqr-img-div">
                    <SquareImg src={require("../../images/breastfeedingAppScreenshot2.png")} alt="screenshot of breastfeeding app" id="top-left-sq-img" onClick={hideAndShowFeeding}/>
                </div>
                <div className="sqr-img-div">
                    <SquareImg src={require("../../images/mooBornScreenshot.png")} alt="screenshot of MooBorn app" onClick={hideAndShowMooBorn}/>
                </div>
            </div>
            {/* <div className='front-bottomRow' >
            This div is only to be added when 3 images already in top row. Keep adding rows every 3 projects
            </div> */}
        </div>
        <div className={backClass}>
            <div className='back-content'>
                <div className="back-and-about">
                    <div className="back-walkthrough">
                        <Button text="Back" id="back-btn" onClick={returnToPortfolio}/>
                        <ProjectTitle text={projectTitle} link={aboutLink}/>
                        <div className="player-wrapper">
                            <ReactPlayer url={projectVideo} controls={true} width="100%" height="100%"/>
                        </div>
                    </div>
                    <div className="back-description">
                        <AboutCard h3="Why" id="back-about" text={aboutTextWhy}/>
                        <AboutCard h3="How" id="back-about" text={aboutTextHow}/>
                        <AboutCard h3="Learning and Development" id="back-about" text={aboutTextNext}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
)
}

export default Portfolio;