import React, {useState} from 'react';


// css
import './Contact.css';


//components
import NavBar from '../../components/NavBar';
import Icon from '../../components/Icon';
import ContactForm from '../../components/ContactForm';


function Contact() {
    return (
        <div className="Contact">
            <NavBar/>
            <div className="contact-content">
                <ContactForm />
            </div>
        </div>
    )

}

export default Contact;