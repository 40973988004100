//imports
import React from "react";

//styling
import './ProjectTitle.css';

export default function ProjectTitle({text, link}) {
    return (
        <a href={link} target="_blank">
            <h2 classname="project-title">{text}</h2>
        </a>
    )
};