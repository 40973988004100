//imports
import React from "react";

//styling
import './SquareImg.css';

export default function SquareImg({src, alt, onClick, id}) {

    //button function 
        //onClick should:
            //hide the Front component
            //reveal the Back component


    return (
            <img src={src} alt={alt} onClick={onClick} className="square-img" target='_blank' id="id" />
    )
};