import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';


// css
import './Home.css';


//components
import NavBar from '../../components/NavBar';
import ProfileImg from '../../components/ProfileImg';
import Button from '../../components/Button';


function Home() {

  //functions
    //View My Work button onClick function and associated variables
  let navigate = useNavigate();
  function viewWork() {
    let path=`Portfolio`;
    navigate(path);
  }

      //ProfileImg functions and states
        //states
    const [pixalImg, setPixalImg] = useState(true);
    const [image, setImage] = useState(require("../../images/SoCAvatar.png"));
    const [altText, setAltText] = useState("Pixel avatar of Jenny");
    const [h1ClassName, setH1ClassName] = useState("home-name-pixal")

      //change image and alt text on click, but allow it to be changed back again on another click
//

    //function to change image
    function changeImage() {
        if (pixalImg===true) {
            setImage(require("../../images/profilePicture.jpg"));
            setAltText("Real picture of Jenny");
            setH1ClassName("home-name-cursive")
            setPixalImg(false);
        } else {
            setImage(require("../../images/SoCAvatar.png"));
            setAltText("Pixel avatar of Jenny");
            setH1ClassName("home-name-pixal")
            setPixalImg(true); 
        }
    }


  return (
    <div className="Home">
      <NavBar />
      <div className="home-content">
        <div className='home-left'>
          <ProfileImg src={image} alt={altText} onClick={changeImage}/>
        </div>
        <div className="home-right">
          <h1 className={h1ClassName} id="mobile-size-h1">Jennifer Lewis-Gandy</h1>
          <h2 className="home-summary">Full stack software developer based in the Midlands, UK.</h2>
          <Button id="home-button" text="View My Work ->" onClick={viewWork}/>
        </div>
      </div>
    </div>
  );
}

export default Home;
