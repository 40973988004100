//imports
import React, {useState} from "react";


//styling
import './ProfileImg.css';

export default function ProfileImg({alt, src, onClick}) {
    return (
        // <div>
            <img src={src} alt={alt} onClick={onClick} className="profile-img"/>
        //  </div> 
    )
};