import React from 'react';

import './Icon.css';


//icon images

export default function Icon({src, altText, href}) {
    return (
        <a href={href} target="_blank">
            <img src={src} alt={altText} className="icon-img" /></a>
            
    )
}